$(() => {
  document.querySelectorAll('main a[href^="#"]').forEach((element) => {
    let targetElement = document.querySelector(element.getAttribute('href'));
    if (!isset(targetElement)) {
      return;
    }

    element.addEventListener('click', (event) => {
      event.preventDefault();
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    });
  });
});